import type { BadgeProps } from '@sravni/react-design-system/dist/types/components/Badge';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';

type BadgeData = {
    text: string;
    color: BadgeProps['color'];
    variant: BadgeProps['variant'];
};

export const getBadgeData = (offer: IClientMicrocreditListItem, hasUtmLabel: boolean): BadgeData | null => {
    const { isPartner, labelText } = offer?.advertising || {};
    const [advertisingLabel] = labelText?.split(';') || [];
    const seoLabel = offer?.seoLabel || '';

    const shouldShowSeoLabel = Boolean(seoLabel) && !hasUtmLabel;
    const shouldShowAdvertisingLabel = Boolean(advertisingLabel);

    if (isPartner) {
        return { text: 'Mы рекомендуем', color: 'orange', variant: 'primary' };
    }
    if (shouldShowAdvertisingLabel) {
        return { text: advertisingLabel, color: 'blue', variant: 'primary' };
    }
    if (shouldShowSeoLabel) {
        return { text: seoLabel, color: 'gray', variant: 'secondary' };
    }

    return null;
};
